import { useEffect, useState } from "react"
import Footer from "../../components/footer"
import Navbar from "../../components/navbar"
import "./style.css"


export default function BasketPage(props) {
  const [total, setTotal] = useState(0);
  const [basket, setBasket] = useState([]);
  const [message, setMessage] = useState("");

  const localBasket = props.basket;
  const setLocalBasket = props.setBasket;

  const removeFromBasket = (event, itemToRemove) => {
    let items = props.basket.filter(item => item.type !== itemToRemove.type || item.id !== itemToRemove.id)

    setTimeout(() => {
      props.setBasket(items.length > 0 ? items : [])
      setBasket(items.length > 0 ? items : [])

      if (items.length === 0) {
        localStorage.setItem("basket", JSON.stringify([]))
      } else {
        localStorage.setItem("basket", JSON.stringify(items))
      }
    }, 210)
  }


  useEffect(() => {
    fetch("/api/payment/check-availability", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        items: localBasket
      })
    })
      .then(res => {
        if (res.ok) {
          res.json()
            .then(data => {
              setBasket(data);

              setMessage("");

              let result = 0;
              for (let item of data) {
                let tmpPrice = item.price;
                if (item.quantity) {
                  tmpPrice *= item.quantity;
                }
                result += tmpPrice;
                if (!item.available) {
                  setMessage(prev => {
                    if (prev === "") {
                      return "Un article de votre panier n'est plus disponible. Merci de le retirer avant de passer au paiement."
                    } else {
                      return "Plusieurs articles de votre panier ne sont plus disponibles. Merci de les retirer avant de passer au paiement."
                    }
                  })
                }
              }
          
              setTotal(result);
            })
        } else {
          alert("Une erreur est survenue, des éléments de votre panier sont introuvables.")
          setLocalBasket([])
        }
      })
  }, [localBasket, setLocalBasket])


  return <>
    <div id="basket-page">
      <h1>Votre panier {(!props.basket || props.basket.length === 0) && "est vide."}</h1>


      {basket && basket.length > 0 ?
        <div id="basket-container">
        
          {basket && basket.map((item, index) => {
            return <div className="basket-item" key={index}>
            <div className="basket-item-image">
              <img src={`/api/${item.type}s/img?id=${item.id}&fn=${item.image}`} alt="preview" />
              {!item.available && <div className="basket-item-unvailable">
                <span>VENDU</span>  
              </div>}
            </div>
            <div className="basket-item-description">
              <h3>{item.name}</h3>
              {item.type === "stage" && <p>Date : {item.date}</p>}
              {item.quantity && <p>Quantité : {item.quantity}</p>}
              <h3>{item.price.toFixed(2)}€</h3>
            </div>

            <svg onClick={(e) => removeFromBasket(e, item)} xmlns="http://www.w3.org/2000/svg" version="1.0" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#F6E9D3" stroke="none">
                <path d="M1195 4033 c-44 -23 -84 -63 -106 -105 -23 -46 -25 -127 -3 -171 9 -18 278 -294 597 -614 l582 -583 -582 -582 c-319 -321 -588 -597 -597 -615 -22 -44 -20 -125 3 -171 24 -46 76 -97 114 -111 36 -14 117 -14 154 -1 15 6 290 273 616 598 l587 587 588 -587 c325 -325 600 -592 615 -598 37 -13 118 -13 154 1 38 14 90 65 114 111 23 46 25 127 3 171 -9 18 -278 294 -597 615 l-582 582 582 583 c319 320 588 596 597 614 21 41 21 125 0 166 -23 45 -63 86 -106 108 -46 23 -127 25 -171 3 -18 -9 -294 -278 -614 -597 l-583 -582 -582 582 c-321 319 -597 588 -615 597 -41 20 -129 20 -168 -1z"/>
              </g>
            </svg>
          </div>
          })}
          
            
          

          <div id="basket-total">
            <h3 id="basket-total-text">Total</h3>
            <h3 id="basket-total-price">{total.toFixed(2)}€</h3>
          </div>

          <p id="basket-message">{message}</p>

          {message === "" && <div id="basket-pay">
            <h3><a href="/payment">Payer</a></h3>
          </div>}
        </div>
        :
        <div id="basket-empty">
          <a id="basket-empty-button" href="/couteaux">
            <h3>Voir la boutique</h3>
          </a>
        </div>
      }
      
    </div>


    <Footer />

    <Navbar basket={props.basket} />
  </>
}