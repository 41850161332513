import React from "react";

import "./style.css"

import Logo256 from "../../assets/images/logo256x256.png"
import KnifesTitle from "../../assets/text/knifes title.png"
import StagesTitle from "../../assets/text/stages title.png"

export default function Header(props) {



  return <header className="subpages-header">
    <img src={Logo256} alt="logo" className="subpages-header-logo" />
    {props.title === "stages" ?
      <img src={StagesTitle} alt="stages title" className="subpages-header-text" />
    :
      <img src={KnifesTitle} alt="knife title" className="subpages-header-text" />
    }
  </header>
}