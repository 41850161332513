import React, { useEffect, useState } from "react";

import "./style.css"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useNavigate, useSearchParams } from "react-router-dom";


export default function ConfirmPaymentPage(props) {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  const paymentId = searchParams.get("payment_intent_client_secret")
  const setBasket = props.setBasket

  useEffect(() => {
    fetch("/api/payment/summary?id=" + paymentId)
      .then(res => {
        if (res.ok) {
          res.json()
            .then(data => {
              setData(data);
            });
        } else {
          setTimeout(() => {
            fetch("/api/payment/summary?id=" + paymentId)
            .then(res => {
              if (res.ok) {
                res.json()
                  .then(data => {
                    setData(data);
                  });
              } else {
                setTimeout(() => {
                  fetch("/api/payment/summary?id=" + paymentId)
                  .then(res => {
                    if (res.ok) {
                      res.json()
                        .then(data => {
                          setData(data);
                        });
                    } else {
                      navigate("/")
                    }
                  })
                }, 1000)
              }
            })
          }, 1000)
        }
      })
  }, [paymentId, navigate])

  useEffect(() => {
    setBasket([]);
  }, [setBasket])

  return <>
    {data && <>
      <div id="confirm-payment-page">
        <h1>Merci pour votre commande !</h1>

        <p id="confirm-payment-message">Nous avons bien reçu votre commande, un mail de confirmation a été envoyé à <u>{data.user.mail}</u>.
          <br/>
          Vos articles seront envoyés sous les plus brefs délais, nous vous informerons par mail.
        </p>

        {data && data.items.map((item, index) => {
          return <div className="basket-item" key={index}>
          <div className="basket-item-image">
            <img src={`/api/${item.type}s/img?id=${item.id}&fn=${item.image}`} alt="preview" />
          </div>
          <div className="basket-item-description">
            <h3>{item.name}</h3>
            <p>Quantité : {item.quantity}</p>
            {item.type === "stage" && <p>Date : {item.date}</p>}
            <h3>{item.price.toFixed(2)}€</h3>
          </div>
        </div>
        })}

        <div id="basket-total">
          <h3 id="basket-total-text">Total</h3>
          <h3 id="basket-total-price">{data.total_price.toFixed(2)}€</h3>
        </div>

        <div id="confirm-payment-page-addresses">
          <div className="confirm-payment-page-address">
            <h3>Adresse de facturation</h3>
            <p>
              {data.user.billing_address.firstname} {data.user.billing_address.name}<br />
              {data.user.billing_address.street}<br />
              {data.user.billing_address.options && <>{data.user.billing_address.options}<br /></>}
              {data.user.billing_address.zipcode} {data.user.billing_address.city}<br />
              {data.user.billing_address.country}<br />
              {data.user.phone}<br />
              {data.user.mail}
            </p>
          </div>

          <div className="confirm-payment-page-address">
            <h3>Adresse de livraison</h3>
            {data.user.shipping_address ?
              <p>
                {data.user.shipping_address.firstname} {data.user.shipping_address.name}<br />
                {data.user.shipping_address.street}<br />
                {data.user.shipping_address.options && <>{data.user.shipping_address.options}<br /></>}
                {data.user.shipping_address.zipcode} {data.user.shipping_address.city}<br />
                {data.user.shipping_address.country}
              </p>    
              :
              <p>
                {data.user.billing_address.firstname} {data.user.billing_address.name}<br />
                {data.user.billing_address.street}<br />
                {data.user.billing_address.options && <>{data.user.billing_address.options}<br /></>}
                {data.user.billing_address.zipcode} {data.user.billing_address.city}<br />
                {data.user.billing_address.country}
              </p>
            }
          </div>
        </div>

      </div>
    </>}

    <Footer />

    <Navbar basket={props.basket}/>
  </>
} 