import React from "react";
import "./style.css"


export default function KnifeBox(props) {



  return <a className="knife-box" href={`/couteaux/${props.data.id}`}>
    <img src={`/api/knifes/img?id=${props.data.id}&fn=${props.data.image}`} alt="knife" />
    <div className="knife-box-infos">
      <p>{props.data.name}</p>
      <p>{props.data.price.toFixed(2)}€</p>
    </div>
    {props.data.available ?
      <div className="knife-box-available">
        DISPO
      </div>
      :
      <div className="knife-box-unavailable">
        <span>VENDU</span>
      </div>
    }
  </a>
}