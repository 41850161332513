import React from "react";

import "./style.css"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

export default function CgvPage(props) {

  return (
    <>
    <div id="cgv-page">
      <h1>Conditions générales</h1>

      <p>
          Les présentes conditions générales de vente sont conclues d’une part par la société Forgeur de l'âme

          dont le siège social est situé à 91 rue chanteloup 53000 Laval, ci-après dénommée Forgeur et gérant

          le site www.forgeurdelame.fr et, d’autre part, par toute personne physique ou morale souhaitant

          procéder à un achat via le site internet Forgeur de l'âme www.forgeurdelame.fr dénommée ci-après

          ” l’acheteur “.


          <br/><br/>
          <strong>ARTICLE 1. OBJET</strong>
          <br/>

          Les présentes conditions de vente visent à définir les relations contractuelles entre Forgeur de l'âme

          et l’acheteur et les conditions applicables à tout achat effectué par le biais du site internet

          www.forgeurdelame.fr. L’acquisition d’un produit à travers le présent site implique une acceptation

          sans réserve par l’acheteur des présentes conditions de vente dont l’acheteur reconnaît avoir pris

          connaissance préalablement à sa commande. Avant toute transaction, l’acheteur déclare d’une part

          que l’achat de produits sur le site www.forgeurdelame.fr est sans rapport direct avec son activité

          professionnelle et est limité à une utilisation strictement personnelle et d’autre part avoir la pleine

          capacité juridique, lui permettant de s’engager au titre des présentes conditions générales de ventes.

          La société FORGEURDELAME conserve la possibilité de modifier à tout moment ces conditions

          de ventes, afin de respecter toute nouvelle réglementation ou dans le but d’améliorer l’utilisation de

          son site. De ce fait, les conditions applicables seront celles en vigueur à la date de la commande par

          l’acheteur.


          <br/><br/>
          <strong>ARTICLE 2. PRODUITS</strong>
          <br/>

          Les produits proposés sont ceux qui figurent sur le site www.forgeurdelame.fr de la société

          FORGEURDELAME , dans la limite des stocks disponibles. La société FORGEURDELAME se

          réserve le droit de modifier à tout moment l’assortiment de produits. Chaque produit est présenté

          sur le site internet sous forme d’un descriptif reprenant ses principales caractéristiques techniques

          (contenance, utilisation, composition...). Les photographies sont les plus fidèles possibles mais

          n’engagent en rien le Vendeur. La vente des produits présentés dans le site

          www.forgeurdelame.fr est destinée à tous les acheteurs résidant dans les pays qui autorisent

          pleinement l’entrée sur leur territoire de ces produits. (à modifier éventuellement)


          <br/><br/>
          <strong>ARTICLE 3. TARIFS</strong>
          <br/>

          Les prix figurant sur les fiches produits du catalogue internet et sont des prix en Euros (€) toutes

          taxes comprises (TTC). La société FORGEURDELAME se réserve le droit de modifier ses prix à

          tout moment, étant toutefois entendu que le prix figurant au catalogue le jour de la commande sera

          le seul applicable à l’acheteur. Les prix indiqués comprennent les frais de livraison.


          <br/><br/>
          <strong>ARTICLE 4. COMMANDE ET MODALITÉS DE PAIEMENT</strong>
          <br/>

          La société FORGEURDELAME propose à l’acheteur de commander et régler ses produits, avec 1

          options de paiement :

          – Paiement sécurisé carte bancaire : l’acheteur sélectionne les produits qu’il souhaite commander

          dans le « panier », modifie si besoin (quantités, références...), vérifie l’adresse de livraison ou en

          renseigne une nouvelle. L’étape suivante lui propose de vérifier l’ensemble des informations,

          prendre connaissance et accepter les présentes conditions générales de vente en cochant la case

          correspondante, puis l’invite à valider sa commande en cliquant sur le bouton « Confirmer ma

          commande ». Enfin, l’acheteur est invité à renseigner en toute sécurité ses informations de carte

          bleue. Si le paiement est accepté, la commande est enregistrée et le contrat définitivement formé. Le

          paiement carte bancaire est irrévocable. En cas d’utilisation frauduleuse de celle-ci, l’acheteur

          pourra exiger l’annulation du paiement par carte, les sommes versées seront alors recréditées ou

          restituées. La responsabilité du titulaire d’une carte bancaire n’est pas engagée si le paiement

          contesté a été prouvé effectué frauduleusement, à distance, sans utilisation physique de sa carte.

          Pour obtenir le remboursement du débit frauduleux et des éventuels frais bancaires que l’opération a

          pu engendrer, le porteur de la carte doit contester, par écrit, le prélèvement auprès de sa banque,

          dans les 70 jours suivant l’opération, voire 120 jours si le contrat le liant à celle-ci le prévoit. Les

          montants prélevés sont remboursés par la banque dans un délai maximum d’un mois après réception

          de la contestation écrite formée par le porteur. Aucun frais de restitution des sommes ne pourra être

          mis à la charge du titulaire.

          La confirmation d’une commande entraîne acceptation des présentes conditions de vente, la

          reconnaissance d’en avoir parfaite connaissance et la renonciation à se prévaloir de ses propres

          conditions d’achat. L’ensemble des données fournies et la confirmation enregistrée vaudront preuve

          de la transaction. Si l’acheteur possède une adresse électronique et s’il l’a renseignée sur son bon de

          commande, la société FORGEURDELAME lui communiquera par courrier électronique la

          confirmation de l’enregistrement de sa commande.

          Si l’acheteur souhaite contacter la société FORGEURDELAME, il peut le faire soit par email à

          l’adresse suivante : forgeurdelame@gmail.com, soit par téléphone au 0638422409.


          <br/><br/>
          <strong>ARTICLE 5. RÉSERVE DE PROPRIÉTÉ</strong>
          <br/>

          La société FORGEURDELAME conserve la propriété pleine et entière des produits vendus

          jusqu’au parfait encaissement du prix.


          <br/><br/>
          <strong>ARTICLE 6. RÉTRACTATION</strong>
          <br/>

          En vertu de l’article L121-20 du Code de la consommation, l’acheteur dispose d’un délai de

          quatorze jours ouvrables à compter de la livraison de leur commande pour exercer son droit de

          rétractation et ainsi faire retour du produit au vendeur pour échange ou remboursement sans

          pénalité, à l’exception des frais de retour.


          <br/><br/>
          <strong>ARTICLE 7. LIVRAISON</strong>
          <br/>

          Les livraisons sont faites à l’adresse indiquée sur le bon de commande qui ne peut être que dans la

          zone géographique convenue. Les commandes sont effectuées par La Poste via COLISSIMO, ou

          autres services de livraison avec suivi, remise avec ou sans signature. Les délais de livraison ne sont

          donnés qu’à titre indicatif ; si ceux-ci dépassent trente jours à compter de la commande, le contrat

          de vente pourra être résilié et l’acheteur remboursé. La société FORGEURDELAME pourra fournir

          par e-mail à l’acheteur le numéro de suivi de son colis. L’acheteur est livré à son domicile par son

          facteur. En cas d’absence de l’acheteur, il recevra un avis de passage de son facteur, ce qui lui

          permet de retirer les produits commandés au bureau de Poste le plus proche, pendant un délai

          indiqué par les services postaux. Les risques liés au transport sont à la charge de l’acquéreur à

          compter du moment où les articles quittent les locaux de la société FORGEURDELAME .

          L’acheteur est tenu de vérifier en présence du préposé de La Poste ou du livreur, l’état de

          l’emballage de la marchandise et son contenu à la livraison. En cas de dommage pendant le

          transport, toute protestation doit être effectuée auprès du transporteur dans un délai de trois jours à

          compter de la livraison.


          <br/><br/>
          <strong>ARTICLE 8. GARANTIE</strong>
          <br/>

          Tous les produits fournis par la société FORGEURDELAME bénéficient de la garantie légale

          prévue par les articles 1641 et suivants du Code civil. En cas de non conformité d’un produit vendu,

          il pourra être retourné à la société FORGEURDELAME qui le reprendra, l’échangera ou le

          remboursera. Toutes les réclamations, demandes d’échange ou de remboursement doivent

          s’effectuer par voie postale à l’adresse suivante : LE BLANC 91 rue chanteloup 53000 laval, dans

          un délai de trente jours après livraison.


          <br/><br/>
          <strong>ARTICLE 9. RESPONSABILITÉ</strong>
          <br/>

          La société FORGEURDELAME, dans le processus de vente à distance, n’est tenue que par une

          obligation de moyens. Sa responsabilité ne pourra être engagée pour un dommage résultant de

          l’utilisation du réseau Internet tel que perte de données, intrusion, virus, rupture du service, ou

          autres problèmes involontaires.


          <br/><br/>
          <strong>ARTICLE 10. PROPRIÉTÉ INTELLECTUELLE</strong>
          <br/>

          Tous les éléments du site www.forgeurdelame.fr sont et restent la propriété intellectuelle et

          exclusive de la société FORGEURDELAME. Personne n’est autorisé à reproduire, exploiter, ou

          utiliser à quelque titre que ce soit, même partiellement, des éléments du site qu’ils soient sous forme

          de photo, logo, visuel ou texte.


          <br/><br/>
          <strong>ARTICLE 11. DONNÉES À CARACTÈRE PERSONNEL</strong>
          <br/>

          La société FORGEURDELAME s’engage à préserver la confidentialité des informations fournies

          par l’acheteur, qu’il serait amené à transmettre pour l’utilisation de certains services. Toute

          information le concernant est soumise aux dispositions de la loi n° 78-17 du 6 janvier 1978. A ce

          titre, l’internaute dispose d’un droit d’accès, de modification et de suppression des informations le

          concernant. Il peut en faire la demande à tout moment par mail à l'adresse suivante :

          forgeurdelame@gmail.com


          <br/><br/>
          <strong>ARTICLE 12. RÈGLEMENT DES LITIGES</strong>
          <br/>

          Les présentes conditions de vente à distance sont soumises à la loi française. Pour tous litiges ou

          contentieux, le Tribunal compétent sera celui de Laval (53000)
      </p>
    </div>

    <Footer />

    <Navbar basket={props.basket}/>
    </>
  )
}