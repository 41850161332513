import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form, Row,
} from "react-bootstrap"

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./style.css"
import Navbar from "../../../components/navbar";
import { useNavigate } from "react-router-dom";

export default function AdminCreateKnife(props) {
  const [value, setValue] = useState("");
  const [validated, setValidated] = useState(false);
  const [formFields, setFormFields] = useState({
    "name": "", 
    "price": "",
    "quantity": 1,
    "preview_image": undefined,
    "first_image": undefined,
    "second_image": undefined,
    "third_image": undefined,
    "fourth_image": undefined
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch("/api/admin/is-admin")
      .then(res => {
        if (!res.ok) {
          navigate("/admin")
        } else {
          setIsAdmin(true);
        }
      })
  }, [navigate])

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);
  
    if (form.checkValidity() === false || value.length < 10) {      
      e.stopPropagation();
    } else {
      let formData = new FormData();
      formData.set("name", formFields.name)
      formData.set("description", value)
      formData.set("price", formFields.price)
      formData.set("quantity", formFields.quantity)
      formData.set("preview_image", formFields.preview_image)
      formData.set("first_image", formFields.first_image)
      formData.set("second_image", formFields.second_image)
      formData.set("third_image", formFields.third_image)
      if (formFields.fourth_image)
        formData.set("fourth_image", formFields.fourth_image)

  
      let fetchData = {
        method: "POST",
        body: formData
      }
  
      fetch("/api/knifes/create", fetchData)
        .then(res => {
          if (res.ok) {
            res.json()
              .then(data => {
                navigate("/couteaux/" + data.id)
              })
          } else {
            alert("Une erreur est survenue !")
          }
        })
    } 

    setLoading(false);
    setValidated(true);
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline','strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}]
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
  ]
  
  if (isAdmin) {
    return <>
    <div id="admin-create-knife">
      <h1>Ajouter un couteau</h1>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="form-name">Nom</Form.Label>
            <Form.Control id="form-name" type="text" placeholder="Nom du couteau..." required
              value={formFields.name} onChange={e => setFormFields(prev => {return {...prev, name: e.target.value}})}
            />
          </Form.Group>

          <Form.Group as={Col} md="3">
            <Form.Label htmlFor="form-price">Prix</Form.Label>
            <Form.Control id="form-price" type="number" placeholder="Prix du couteau..." required
              value={formFields.price} onChange={e => setFormFields(prev => {return {...prev, price: e.target.value}})}
            />
          </Form.Group>

          <Form.Group as={Col} md="3">
            <Form.Label htmlFor="form-price">Quantité</Form.Label>
            <Form.Control id="form-price" type="number" placeholder="Quantité..." required
              value={formFields.quantity} onChange={e => setFormFields(prev => {return {...prev, quantity: e.target.value}})}
            />
          </Form.Group>
        </Row>
        
        <Form.Group className="mb-3">
          <Form.Label htmlFor="form-description">Description</Form.Label>
          <div id="text-editor" style={{background: "white"}}>
            <ReactQuill id="form-description" theme="snow" value={value} onChange={setValue}
              modules={modules}
              format={formats}
            />
          </div>
        </Form.Group>

        

        

        <Form.Group className="mb-3">
          <Form.Label htmlFor="form-second-image">Preview image</Form.Label>
          <Form.Control id="form-second-image" type="file" required
            onChange={e => setFormFields(prev => {return {...prev, preview_image: e.target.files[0]}})}
          />
        </Form.Group>


        {formFields.preview_image && 
          <img src={URL.createObjectURL(formFields.preview_image)} alt="preview" id="form-preview-image-small"/>
        }


        <Form.Group className="mb-3">
          <Form.Label htmlFor="form-first-image">1ere image</Form.Label>
          <Form.Control id="form-first-image" type="file" required
            onChange={e => setFormFields(prev => {return {...prev, first_image: e.target.files[0]}})}
          />
        </Form.Group>

        {formFields.first_image && 
          <img src={URL.createObjectURL(formFields.first_image)} alt="preview" className="form-preview-image"/>
        }

        <Form.Group className="mb-3">
          <Form.Label htmlFor="form-second-image">2eme image</Form.Label>
          <Form.Control id="form-second-image" type="file" required
            onChange={e => setFormFields(prev => {return {...prev, second_image: e.target.files[0]}})}
          />
        </Form.Group>

        {formFields.second_image && 
          <img src={URL.createObjectURL(formFields.second_image)} alt="preview" className="form-preview-image"/>
        }

        <Form.Group className="mb-3">
          <Form.Label htmlFor="form-third-image">3eme image</Form.Label>
          <Form.Control id="form-third-image" type="file" required
            onChange={e => setFormFields(prev => {return {...prev, third_image: e.target.files[0]}})}
          />
        </Form.Group>

        {formFields.third_image && 
          <img src={URL.createObjectURL(formFields.third_image)} alt="preview" className="form-preview-image"/>
        }

        <Form.Group className="mb-3">
          <Form.Label htmlFor="form-fourth-image">4eme image</Form.Label>
          <Form.Control id="form-fourth-image" type="file"
            onChange={e => setFormFields(prev => {return {...prev, fourth_image: e.target.files[0]}})}
          />
        </Form.Group>

        {formFields.fourth_image && 
          <img src={URL.createObjectURL(formFields.fourth_image)} alt="preview" className="form-preview-image"/>
        }
        
        <Button type="submit">Ajouter</Button>
      </Form>
    </div>
    <Navbar basket={props.basket} />
  </>
  } else {
    return <></>
  }
}