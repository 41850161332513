import React, { useEffect, useState } from "react";

import "./style.css"
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import Header from "../../components/header";
import KnifeBox from "../../components/knifeBox";


import StagesExplain from "../../components/stagesExplain";

export default function KnifesPage(props) {
  const [maxPrice, setMaxPrice] = useState(500);
  const [filterActive, setFilterActive] = useState("all");
  const [order, setOrder] = useState("latest");
  const [knifes, setKnifes] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetch("/api/knifes/get_list?order=latest&max=500&filter=all&page=1&count=20")
      .then(res => res.json())
      .then(data => setKnifes(data.knifes))
  }, [])


  const updateList = (order_, maxPrice_, filterActive_, page_) => {
    fetch(`/api/knifes/get_list?order=${order_}&max=${maxPrice_}&filter=${filterActive_}&page=${page_}&count=20`)
      .then(res => res.json())
      .then(data => setKnifes(data.knifes))
  }

  const seeMore = () => {
    fetch(`/api/knifes/get_list?order=${order}&max=${maxPrice}&filter=${filterActive}&page=${page + 1}&count=20`)
      .then(res => res.json())
      .then(data => {
        setKnifes(prev => [...prev, ...data.knifes]);
        setPage(prev => prev + 1);
      })
  }



  return <>
    <Header title="Mes couteaux" />
    
    <div id="knifes-filters">
      <div id="all" 
      className={filterActive === "all" ? "active" : ""}
      onClick={() => {
        setFilterActive("all");
        setPage(1);
        updateList(order, maxPrice, "all", 1);
      }}      
      >
        <p className="knifes-filters-category-title">Tous</p>
      </div>
      <div id="availables" 
        className={filterActive === "availables" ? "active" : ""}
        onClick={() => {
          setFilterActive("availables");
          setPage(1);
          updateList(order, maxPrice, "availables", 1);
        }}
      >
        <p className="knifes-filters-category-title">Disponibles</p>
      </div>
      <div id="price">
        <div>
          <p className="knifes-filters-category-title">Prix max</p>
        </div>
        <input type="range" value={maxPrice} 
          onChange={e => setMaxPrice(e.target.value)} max={500} 
          onMouseUp={(e) => {
            setPage(1);
            updateList(order, e.target.value, filterActive, 1);
          }}  
          onTouchEnd={(e) => {
            setPage(1);
            updateList(order, e.target.value, filterActive, 1);
          }}
        />
        <input type="number" value={maxPrice} 
          onChange={e => setMaxPrice(e.target.value)} 
          onBlur={(e) => {
            setPage(1);
            updateList(order, e.target.value, filterActive, 1);
          }}  
        />
        <p>€</p>
      </div>
      <div id="order-by">
        <div>
          <p className="knifes-filters-category-title">Trier par : </p>
        </div>
        <select
          onChange={e => {
            setOrder(e.target.value);
            setPage(1);
            updateList(e.target.value, maxPrice, filterActive, 1);
          }}
          value={order}
        >
          <option value="latest">Le plus récent</option>
          <option value="lower">Prix croissant</option>
          <option value="higher">Prix décroissant</option>
        </select>
      </div>
    </div>

    <div id="knife-list">
      {knifes.map((knife, i) => {
        return <KnifeBox key={i} data={knife}/>
      })}
    </div>

    <div id="knife-list-more"
      onClick={() => {
        seeMore();
      }}
    >
      <p>Voir plus</p>
    </div>

    <StagesExplain />

    <Footer />

    <Navbar basket={props.basket} />
  </>
}
