import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import axios from "axios"

import "./style.css"

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();


  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Le paiement a été effectué avec succès !");
          break;
        case "processing":
          setMessage("Paiement en cours...");
          break;
        case "requires_payment_method":
          setMessage("Le paiement n'a pas abouti, merci de réessayer.");
          break;
        default:
          setMessage("Une erreur est survenue.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const billingInfosValidated = props.checkBillingInfos();
    if (!billingInfosValidated) {
      return;
    }


    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    let paymentError = false;

    try {      
      try {
        await axios.post("/api/payment/start-payment", 
          {
            client_secret: props.clientSecret,
            amount: props.totalPrice,
            items: props.basket,
            user: props.user
          }, 
          {
            headers: {'Content-Type': "application/json"}
          })
      } catch (error) {
        paymentError = true;
        fetch("/api/payment/cancel-payment", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({
            client_secret: props.clientSecret
          })
        })
        setMessage("Une erreur est survenue. Merci de réessayer.")
        setIsLoading(false);
        return;
      }


      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "https://forgeurdelame.fr/confirm-payment",
        }
      });

 
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      if (error) {
        paymentError = true;
        fetch("/api/payment/cancel-payment", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({
            client_secret: props.clientSecret
          })
        })

        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("Une erreur inattendue est survenue.");
        }
      }
    } catch {

    } finally {
      if (!paymentError) {
        props.setBasket([]);
      }
      setIsLoading(false);
    }

  };

  const paymentElementOptions = {
    layout: "tabs",
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <p id="payment-info-message">
        En passant commande, vous acceptez les conditions générales de vente du Forgeur de l'Ame.
      </p>
      <button disabled={isLoading || !stripe || !elements} id="payment-submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : <span id="button-text-pay">Payer</span>}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}