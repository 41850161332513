import React, { useEffect, useState } from "react";

import "./style.css"
import Header from "../../components/header";
import Navbar from "../../components/navbar";
import StagesExplain from "../../components/stagesExplain";
import StageBox from "../../components/stageBox";
import Footer from "../../components/footer";
import MessageBox from "../../components/messageBox";


export default function StagesPage(props) {
  const [stages, setStages] = useState([])
  const [msgText, setMsgText] = useState("");
  const [msgBoxOpen, setMsgBoxOpen] = useState(false);

  useEffect(() => {
    fetch("/api/stages/get_list")
      .then(res => res.json())
      .then(data => {
        for (let item of props.basket) {
          for (let stage of data.stages) {
            if (item.id === stage.id && item.type === "stage") {
              stage.inBasket = true
            } else {
              stage.inBasket = false
            }
          }
        }

        setStages(data.stages);
      })
  }, [props.basket])


  const addToBasket = (data) => {
    props.setBasket(prev => {
      return [...prev, {
        "id": data.id,
        "name": data.name,
        "price": data.price,
        "date": data.date,
        "type": "stage",
        "image": data.image
      }]
    })

    setMsgText(`"${data.name}" a été ajouté à votre panier`)
    setMsgBoxOpen(true);
  }

  return <>
    <Header title="stages"/>

    <section id="stages-booking">
      <h2>Réserver un stage de formation</h2>

      {stages.length === 0 && <h3>Aucune formation pour le moment !</h3>}

      {stages.map(stage => <StageBox key={stage.id} data={stage} addFunction={() => addToBasket(stage)} />)}
    </section>

    <StagesExplain />

    <Footer />

    {msgBoxOpen && <MessageBox text={msgText} close={() => setMsgBoxOpen(false)} />}

    <Navbar basket={props.basket} />
  </>
}