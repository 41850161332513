import React from "react";

import "./style.css"
import Navbar from "../../components/navbar";

export default function GaleryPage(props) {

  return (
    <>
    <div id="galery-page">
      <h1>Cette page n'est pas encore disponible.</h1>
    </div>
    
    <Navbar basket={props.basket}/>
    </>
  )
}