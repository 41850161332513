import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactQuill from "react-quill";

import "./style.css"
import { useNavigate } from "react-router-dom";

export default function AdminCreateStage(props) {
  const [value, setValue] = useState("");
  const [validated, setValidated] = useState(false);
  const [formFields, setFormFields] = useState({
    "name": "", 
    "price": "",
    "image": undefined,
    "date": ""
  })
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("/api/admin/is-admin")
      .then(res => {
        if (!res.ok) {
          navigate("/admin")
        } else {
          setIsAdmin(true);
        }
      })
  }, [navigate])

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    
    if (form.checkValidity() === false || value.length < 10) {      
      e.stopPropagation();
    } else {
      let formData = new FormData();
      formData.set("name", formFields.name)
      formData.set("description", value)
      formData.set("price", formFields.price)
      formData.set("image", formFields.image)
      formData.set("date", formFields.date)


      let fetchData = {
        method: "POST",
        body: formData
      }
  
      fetch("/api/stages/create", fetchData)
        .then(res => {
          if (res.ok) {
            res.json()
              .then(data => {
                navigate("/stages")
              })
          } else {
            alert("Une erreur est survenue !")
          }
        })
    } 

    setValidated(true);

  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline','strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}]
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
  ]

  if (isAdmin) {
    return <>
    <div id="admin-create-stage">
      <h1>Ajouter une formation</h1>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="8">
            <Form.Label htmlFor="form-name">Nom</Form.Label>
            <Form.Control id="form-name" type="text" placeholder="Nom de la formation..." required
              value={formFields.name} onChange={e => setFormFields(prev => {return {...prev, name: e.target.value}})}
            />
          </Form.Group>

          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="form-price">Prix</Form.Label>
            <Form.Control id="form-price" type="number" placeholder="Prix de la formation..." required
              value={formFields.price} onChange={e => setFormFields(prev => {return {...prev, price: e.target.value}})}
            />
          </Form.Group>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="form-first-date">Date</Form.Label>
          <Form.Control id="form-date" type="date" required
            onChange={e => setFormFields(prev => {return {...prev, date: e.target.value}})}
          />
        </Form.Group>
        
        <Form.Group className="mb-3">
          <Form.Label htmlFor="form-description">Description</Form.Label>
          <div id="text-editor" style={{background: "white"}}>
            <ReactQuill id="form-description" theme="snow" value={value} onChange={setValue}
              modules={modules}
              format={formats}
            />
          </div>
        </Form.Group>

        

        <Form.Group className="mb-3">
          <Form.Label htmlFor="form-first-image">Image</Form.Label>
          <Form.Control id="form-first-image" type="file" required
            onChange={e => setFormFields(prev => {return {...prev, image: e.target.files[0]}})}
          />
        </Form.Group>

        {formFields.image && 
          <img src={URL.createObjectURL(formFields.image)} alt="preview" id="form-preview-image-small"/>
        }

        <Button type="submit">Ajouter</Button>
      </Form>
    </div>

    <Navbar basket={props.basket} />
  </>
  } else {
    return <></>
  }
}