import React, { useEffect, useState } from "react";
import "./style.css"

import Logo from "../../assets/images/logo80x80.png"


export default function Navbar (props) {
  const [itemsCount, setItemsCount] = useState(0);

  const toggleMobileMenu = () => {
    const mobileMenu = document.querySelector(".mobile-menu")
    const hamburgerIcon = document.querySelector("#hamburger-icon")

    mobileMenu.classList.toggle("open");
    hamburgerIcon.classList.toggle("open");
  }

  useEffect(() => {
    let res = 0;

    for (let item of props.basket) {
      if (item.type === "knife") {
        res += parseInt(item.quantity);
      } else {
        res += 1;
      }
    }

    setItemsCount(res);
  }, [props.basket])

  return <>
    <nav id="desktop-nav">
      <div>
        <a href="/couteaux" className="desktop-nav-text">Couteaux</a>
      </div>
      <div>
        <a href="/stages" className="desktop-nav-text">Stages</a>
      </div>
      <div>
        <a href="/">
          <img src={Logo} alt="logo"/>
        </a>
      </div>
      <div>
        <a href="/galery" className="desktop-nav-text">Galerie photo</a>
      </div>
      <div>
        <a href="contact" className="desktop-nav-text">Contact</a>
      </div>
    </nav>
    <nav id="mobile-nav">
      <a href="/">
        <img src={Logo} alt="logo"/>
      </a>
      <div id="hamburger-icon" onClick={toggleMobileMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <ul className="mobile-menu">
        <li><a href="/couteaux">Couteaux</a></li>
        <li className="divider" />
        <li><a href="/stages">Stages</a></li>
        <li className="divider" />
        <li><a href="/galerie">Galerie photo</a></li>
        <li className="divider" />
        <li><a href="contact">Contact</a></li>
      </ul>
    </nav>

    {/* Basket */}
    <a id="basket-link" href="/basket">
      <div id="basket">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#F6E9D3" stroke="none">
          <path d="M162 4843 c-59 -21 -126 -91 -147 -154 -32 -96 -4 -203 71 -269 71
          -62 84 -64 366 -68 l257 -4 11 -31 c9 -27 974 -2800 1017 -2924 l15 -43 1516
          0 1517 0 51 24 c159 74 189 283 58 410 -72 70 18 66 -1454 66 l-1327 0 -523
          1505 -523 1505 -431 -1 c-357 0 -438 -3 -474 -16z"/>
          <path d="M1953 3970 c-39 -23 -53 -48 -53 -94 0 -20 106 -347 244 -752 214
          -628 249 -721 277 -750 66 -68 -10 -64 1118 -64 1124 0 1056 -4 1120 63 31 33
          47 80 247 769 117 403 214 746 214 761 0 18 -10 39 -29 58 l-29 29 -1539 0
          c-1505 0 -1539 0 -1570 -20z"/>
          <path d="M2193 1145 c-178 -48 -305 -188 -335 -370 -21 -129 26 -275 119 -370
          100 -101 198 -139 344 -133 87 3 104 7 171 40 175 87 270 266 247 463 -31 254
          -300 436 -546 370z"/>
          <path d="M4077 1139 c-208 -49 -364 -283 -329 -494 61 -368 496 -512 757 -251
          233 232 131 639 -183 736 -68 21 -177 25 -245 9z"/>
          </g>
        </svg>

        <div id="basket-count">
          {itemsCount}
        </div>
      </div>
    </a>
  </>
}