import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import "./style.css"
import { Button, Form, Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Navbar from "../../../components/navbar";



export default function AdminEditItem(props) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
    price: 0,
    available: null
  })
  const [validated, setValidated] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/api/admin/${params.type}/details?id=${params.id}`)
      .then(res => {
        if (res.ok) {
          setIsAdmin(true);
          res.json()
            .then(data => setData(data));
        } else {
          navigate("/admin")
        }
      })
  }, [params.type, params.id, navigate])

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline','strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}]
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
  ]

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
  
    if (form.checkValidity() === false || data.description.length < 10) {      
      e.stopPropagation();
    } else {
      if (window.confirm("Confirmez les modifications ?")) {
        fetch(`/api/admin/${params.type}/edit`, {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }) 
          .then(res => {
            if (res.ok) {
              navigate("/admin/list/" + params.type + "s")
            } else {
              alert("Une erreur est survenue.")
            }
          })
      }
    } 

    setValidated(true);
  }

  const handleDelete = () => {
    if (window.confirm("Voulez-vous vraiment supprimer cet article ?")) {
      fetch(`/api/admin/${params.type}/delete`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          id: params.id
        })
      })
        .then(res => {
          if (res.ok) {
            navigate("/admin/list/" + params.type + "s")
          } else {
            res.json()
              .then(data => {
                alert("Erreur : " + data.message)
              })
          }
        })
    }
  }

  if (isAdmin) {
    return <>
      <div id="admin-create-knife">
        <h1>Modifier</h1>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="6">
              <Form.Label htmlFor="form-name">Nom</Form.Label>
              <Form.Control id="form-name" type="text" placeholder="Nom du couteau..." required
                value={data.name} onChange={e => setData(prev => {return {...prev, name: e.target.value}})}
              />
            </Form.Group>

            <Form.Group as={Col} md="3">
              <Form.Label htmlFor="form-price">Prix</Form.Label>
              <Form.Control id="form-price" type="number" placeholder="Prix du couteau..." required
                value={data.price} onChange={e => setData(prev => {return {...prev, price: e.target.value}})}
              />
            </Form.Group>
          
            {params.type === "knife" ?
              <Form.Group as={Col} md="3">
                <Form.Label htmlFor="form-availability">Quantité RESTANTE</Form.Label>
                <Form.Control id="form-availability" value={data.available} onChange={(e) => setData(prev => {
                  return {...prev, available: e.target.value}
                })} />
              </Form.Group>
              :
              <Form.Group as={Col} md="3">
                <Form.Label htmlFor="form-availability">Disponible</Form.Label>
                <Form.Check id="form-availability" checked={data.available} onChange={() => setData(prev => {
                  return {...prev, available: !prev.available}
                })} />
              </Form.Group>
            }

          </Row>
          
          <Form.Group className="mb-3">
            <Form.Label htmlFor="form-description">Description</Form.Label>
            <div id="text-editor" style={{background: "white"}}>
              <ReactQuill id="form-description" theme="snow" 
                value={data.description} onChange={e => setData(prev => {return {...prev, description: e}})}
                modules={modules}
                format={formats}
              />
            </div>
          </Form.Group>

          

          <Button style={{background: "#b11717", borderColor: "#b11717", marginRight: 15}}
            onClick={handleDelete}
          >Supprimer</Button>
          <Button type="submit">Modifier</Button>
        </Form>
      </div>
      <Navbar basket={props.basket} />
    </> 
  } else {
    return <></>
  }
}