import React, { useEffect, useState } from "react"
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom"
import HomePage from "./pages/homepage"
import ErrorPage from "./pages/error";
import KnifesPage from "./pages/knifesPage";

import AdminCreateKnife from "./pages/admin/adminCreateKnife"
import KnifeDetailsPage from "./pages/knifeDetailsPage";
import StagesPage from "./pages/stagesPage";
import BasketPage from "./pages/basketPage";
import PaymentPage from "./pages/paymentPage";
import AdminCreateStage from "./pages/admin/adminCreateStage";
import ConfirmPaymentPage from "./pages/confirmPaymentPage";
import AdminLogin from "./pages/admin/adminLogin";
import AdminDashboard from "./pages/admin/adminDashboard";
import AdminOrderDetails from "./pages/admin/adminOrderDetails";
import AdminListKnifes from "./pages/admin/adminListItems";
import AdminEditItem from "./pages/admin/adminEditItem";
import ContactPage from "./pages/contactPage";
import CgvPage from "./pages/cgv";
import GaleryPage from "./pages/galery";




function App() {
  const storedItems = JSON.parse(localStorage.getItem("basket"))
  const [basket, setBasket] = useState(storedItems ? storedItems : [])

  useEffect(() => {
    // Store to localStorage
    localStorage.setItem("basket", JSON.stringify(basket));
  }, [basket])

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage basket={basket} />,
      errorElement: <ErrorPage basket={basket} />
    },
    {
      path: "/couteaux",
      element: <KnifesPage basket={basket} />
    },
    {
      path: "/couteaux/:knifeId",
      element: <KnifeDetailsPage basket={basket} setBasket={setBasket} /> 
    },
    {
      path: "/stages",
      element: <StagesPage basket={basket} setBasket={setBasket}/>
    },
    {
      path: "/contact",
      element: <ContactPage basket={basket} />
    },
    {
      path: "/basket",
      element: <BasketPage basket={basket} setBasket={setBasket}/>
    },
    {
      path: "/payment",
      element: <PaymentPage basket={basket}/>
    },
    {
      path: "/confirm-payment",
      element: <ConfirmPaymentPage basket={basket} setBasket={setBasket} />
    },
    {
      path: "/cgv",
      element: <CgvPage basket={basket} />
    },
    {
      path: "/galery",
      element: <GaleryPage basket={basket} setBasket={setBasket} />
    },
    // ADMIN
    {
      path: "/admin",
      element: <AdminLogin basket={basket} />
    },
    {
      path: "/admin/dashboard",
      element: <AdminDashboard basket={basket} />
    },
    {
      path: "/admin/order-details/:orderId",
      element: <AdminOrderDetails basket={basket} />
    },
    {
      path: "/admin/create-knife",
      element: <AdminCreateKnife basket={basket} />
    },
    {
      path: "/admin/create-stage",
      element: <AdminCreateStage basket={basket} />
    },
    {
      path: "/admin/list/:type",
      element: <AdminListKnifes basket={basket} />
    },
    {
      path: "/admin/edit/:type/:id",
      element: <AdminEditItem basket={basket} />
    },
  ])

  return (
    <RouterProvider router={router} />
  );
}

export default App;
