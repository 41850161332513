import React from "react";

import Logo80 from "../../assets/images/logo80x80.png"
import Instagram from "../../assets/icons/instagram.svg"

import "./style.css"

export default function Footer(props) {


  return <footer>
    <div id="footer-top">
      <div>
        <div id="instagram">
          <img src={Instagram} alt="instagram logo" />
          <a href="https://instagram.com/forgeurdelame" rel="noreferrer" target="_blank">@forgeurdelame</a>
        </div>
        <a href="/cgv">Conditions générales</a>
      </div>
      <img src={Logo80} alt="logo" id="footer-logo" />
    </div>
    <p>© 2023 - Forgeur de l’Ame | Tous droits réservés | Réalisé par Gaëtan VETILLARD</p>
  </footer>
}