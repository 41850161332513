import React, { useEffect, useState } from "react";

import "./style.css"
import Navbar from "../../../components/navbar";

import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

export default function AdminOrderDetails(props) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [packageNumber, setPackageNumber] = useState("");
  const params = useParams();


  useEffect(() => {
    fetch("/api/payment/summary?id=" + params.orderId)
      .then(res => {
        if (res.ok) {
          setIsAdmin(true);
          res.json()
            .then(data => setData(prev => {
              if (!prev) {
                return data;
              } else {
                return prev;
              }
            }));
        } else {
          alert("Une erreur est survenue.");
          navigate("/admin");
        }
      })
  }, [params.orderId, navigate])


  const handleSubmit = e => {
    e.preventDefault();

    if (window.confirm("Êtes-vous sûr de vouloir marquer cette commande comme envoyée ?")) {
      fetch("/api/payment/send-order", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          id: params.orderId,
          package_number: packageNumber
        })
      })
        .then(res => {
          if (res.ok) {
            
          } else {

          }
        })
    }
  }

  if (isAdmin) {
    return <>
    <div id="admin-order-details-page">
      {data && <>
        <h1>Commande n°{data.order.id}</h1>
        <h2>Statut : {data.order.status}</h2>
        <p id="order-details-date">{data.order.date}</p>
        {data && data.items.map((item, index) => {
          return <div className="basket-item" key={index}>
          <div className="basket-item-image">
            <img src={`/api/${item.type}s/img?id=${item.id}&fn=${item.image}`} alt="preview" />
          </div>
          <div className="basket-item-description">
            <h3>{item.name}</h3>
            {item.type === "stage" && <p>Date : {item.date}</p>}
            {item.type === "knife" && item.quantity > 1 && <p>Quantité : {item.quantity}</p>}
            <h3>{item.price.toFixed(2)}€</h3>
          </div>
        </div>
        })}

        <div id="basket-total">
          <h3 id="basket-total-text">Total</h3>
          <h3 id="basket-total-price">{data.total_price.toFixed(2)}€</h3>
        </div>

        <div id="confirm-payment-page-addresses">
          <div className="confirm-payment-page-address">
            <h3>Adresse de facturation</h3>
            <p>
              {data.user.billing_address.firstname} {data.user.billing_address.name}<br />
              {data.user.billing_address.street}<br />
              {data.user.billing_address.options && <>{data.user.billing_address.options}<br /></>}
              {data.user.billing_address.zipcode} {data.user.billing_address.city}<br />
              {data.user.billing_address.country}<br />
              {data.user.phone}<br />
              {data.user.mail}
            </p>
          </div>

          <div className="confirm-payment-page-address">
            <h3>Adresse de livraison</h3>
            {data.user.shipping_address ?
              <p>
                {data.user.shipping_address.firstname} {data.user.shipping_address.name}<br />
                {data.user.shipping_address.street}<br />
                {data.user.shipping_address.options && <>{data.user.shipping_address.options}<br /></>}
                {data.user.shipping_address.zipcode} {data.user.shipping_address.city}<br />
                {data.user.shipping_address.country}
              </p>    
              :
              <p>
                {data.user.billing_address.firstname} {data.user.billing_address.name}<br />
                {data.user.billing_address.street}<br />
                {data.user.billing_address.options && <>{data.user.billing_address.options}<br /></>}
                {data.user.billing_address.zipcode} {data.user.billing_address.city}<br />
                {data.user.billing_address.country}
              </p>
            }
          </div>
        </div>

        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Numéro de suivi</Form.Label>
            <Form.Control type="text" value={packageNumber} onChange={e => setPackageNumber(e.target.value)} />
          </Form.Group>
          <Button type="submit">
            Marquer comme envoyée
          </Button>
        </Form>

      </>}
    </div>

  
    <Navbar basket={props.basket} />
  </>
  } else {
    return <></>
  }
}