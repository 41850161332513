import React, { useState } from "react";

import "./style.css"

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { Button, Col, Form, Row } from "react-bootstrap";



export default function ContactPage(props) {
  const [validated, setValidated] = useState(false);
  const [formInput, setFormInput] = useState({
    name: "",
    firstname: "",
    mail: "",
    message: ""
  })
  const [message, setMessage] = useState("");
  const [sended, setSended] = useState(false);

  const handleSubmit = e => {
    const form = e.currentTarget;
    e.preventDefault();

    if (sended) {
      return;
    }

    if (form.checkValidity === false) {
      e.stopPropagation();
    } else {
      fetch("/api/contact", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(formInput)
      })
        .then(res => {
          if (res.ok) {
            setSended(true);
            setMessage("Votre message a bien été envoyé !")
          } else {
            setMessage("Une erreur est survenue !")
          }
        })
    }

    setValidated(true);
  }


  return <>
    <div id="contact-page">
      <h1>Contactez-moi !</h1>
      <p>Besoin de plus d'informations ? N'hésitez-pas, et contactez-moi via le formulaire ci-dessous, je vous répondrai par mail sous les plus brefs délais !</p>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="form-name">Nom</Form.Label>
            <Form.Control id="form-name" type="text" placeholder="Nom..." required
              value={formInput.name} onChange={e => setFormInput(prev => {return {...prev, name: e.target.value}})}
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="form-firstname">Prénom</Form.Label>
            <Form.Control id="form-firstname" type="text" placeholder="Prénom..." required
              value={formInput.firstname} onChange={e => setFormInput(prev => {return {...prev, firstname: e.target.value}})}
            />
          </Form.Group>
        </Row>

        <Form.Group class="mb-3">
          <Form.Label htmlFor="form-mail">Adresse mail</Form.Label>
          <Form.Control id="form-mail" type="text" placeholder="Adresse mail..." required
            value={formInput.mail} onChange={e => setFormInput(prev => {return {...prev, mail: e.target.value}})}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="form-description">Votre message</Form.Label>
          <Form.Control as="textarea" placeholder="Bonjour, ..." required
            value={formInput.message} onChange={e => setFormInput(prev => {return {...prev, message: e.target.value}})}
          />
        </Form.Group>

        <Button type="submit">Envoyer</Button>
      </Form>

      <p>{message}</p>
    </div>
    
    <Footer />

    <Navbar basket={props.basket} />
  </>
}