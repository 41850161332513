import { useRouteError } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import "./style.css"
import Navbar from "../../components/navbar";

export default function ErrorPage(props) {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <>
    <div id="error-page">
      <h1>Hum, il semblerait qu'une erreur est survenue !</h1>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <div id="discover-button"  onClick={() => navigate("/")}>
        <p>Retour à l'accueil</p>
        <div id="discover-button_underline" />
      </div>
    </div>
    
    <Navbar basket={props.basket}/>
    </>
  )
}