import React from "react";

import FormationImage from "../../assets/images/1.JPG"
import EnclumeIcon from "../../assets/icons/enclume.svg"

export default function StagesExplain() {

  return <section id="stages" className="section-homepage">
  <div className="section-homepage-content">
    <div className="section-title">
      <img src={EnclumeIcon} alt="enclume" />
      <div className="section-title-text-white">
        <h2>Mes stages de formation</h2>
        <div className="section-title-divider" />
      </div>
    </div>
    <p>
    Depuis la création de l'homme la forge a eu un rôle primordial dans notre évolution, 
    cet art autrefois vital se voit aujourd'hui disparaître. En venant découvrir les 
    secrets et techniques de la forge vous repartirez, à la fin de la formation, 
    non seulement avec une œuvre que vous aurez imaginée et fabriquée, mais également 
    avec un savoir-faire unique et ancestral. Qui sait, peut être que demain ça sera 
    vous qui allumerez les flammes de futurs forgerons
    </p>
  </div>
  <img src={FormationImage} alt="stages" className="section-homepage-image" style={{objectPosition: "inherit"}}/>
</section>
}