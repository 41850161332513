import React, { useEffect, useState } from "react";

import "./style.css"
import Navbar from "../../../components/navbar";
import { useNavigate } from "react-router-dom";


export default function AdminDashboard(props) {
  const [data, setData] = useState({
    todo: [],
    done: []
  });
  const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetch("/api/admin/orders-list")
      .then(res => {
        if (res.ok) {
          setIsAdmin(true);
          res.json()
            .then(data => setData(data));
        } else {
          navigate("/admin");
        }
      })
  }, [navigate])


  if (isAdmin) {
    return <>
    <div id="admin-dashboard-page">
      <h1>Tableau de bord</h1>

      <div id="admin-dashboard-create-buttons">
        <a href="/admin/create-knife">
          <div className="admin-dashboard-create-button">
            +<br/>Nouveau couteau
          </div>
        </a>
        <a href="/admin/create-stage">
          <div className="admin-dashboard-create-button">
            +<br/>Nouveau stage
          </div>
        </a>
      </div>

      <div id="admin-dashboard-list-buttons">
        <a href="/admin/list/knifes">
          <div className="admin-dashboard-list-button">
            Liste des couteaux
          </div>
        </a>
        <a href="/admin/list/stages">
          <div className="admin-dashboard-list-button">
            Liste des stages
          </div>
        </a>
      </div>
      
      <div id="admin-dashboard-stats">

      </div>

      <div id="admin-dashboard-orders">
        <div id="orders-to-do">
          <h2>Commandes à traiter</h2>
          {data.todo.length > 0 ?
            <table className="orders-list">
              <thead>
                <tr>
                  <th>N°</th>
                  <th>Client</th>
                  <th>Date</th>
                  <th>Articles</th>
                  <th>Statut</th>
                </tr>  
              </thead>

              <tbody>
                {data.todo.map(order => <tr key={order.id} onClick={() => {
                  navigate("/admin/order-details/" + order.id)
                }}>
                  <td>{order.id}</td>
                  <td>{order.client.firstname} {order.client.name}</td>
                  <td>{order.date}</td>
                  <td>{order.items}</td>
                  <td>{order.status}</td>
                </tr>)}

              </tbody>
            </table>
            :
            <p>Aucune commande</p>
          }
        </div>

        <div id="orders-sent">
          <h2>Commandes envoyées</h2>
          {data.done.length > 0 ?
            <table className="orders-list">
              <thead>
                <tr>
                  <th>N°</th>
                  <th>Client</th>
                  <th>Date</th>
                  <th>Articles</th>
                  <th>N° de suivi</th>
                </tr>  
              </thead>

              <tbody>
                {data.done.map(order => <tr key={order.id} onClick={() => {
                  navigate("/admin/order-details/" + order.id)
                }}>
                  <td>{order.id}</td>
                  <td>{order.client.firstname} {order.client.name}</td>
                  <td>{order.date}</td>
                  <td>{order.items}</td>
                  <td>{order.package_number ? order.package_number : ""}</td>
                </tr>)}

              </tbody>
            </table>
            :
            <p>Aucune commande</p>
          }
        </div>
      </div>
    </div>

    <Navbar basket={props.basket} />
  </>
  } else {
    return <></>
  }
}