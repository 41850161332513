import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";

import Logo1024 from "../../assets/images/logo1024x1024.png"
import HeadTitle from "../../assets/text/head title.png"
import FabricationImage from "../../assets/images/3.JPG"
import EnclumeIcon from "../../assets/icons/enclume.svg"

import "./style.css"
import KnifeBox from "../../components/knifeBox";
import Footer from "../../components/footer";
import StagesExplain from "../../components/stagesExplain";


export default function HomePage (props) {
  const [knifes, setKnifes] = useState([]);

  useEffect(() => {
    fetch("/api/knifes/get_list?order=latest&max=1000&filter=all&page=1&count=12")
      .then(res => res.json())
      .then(data => {
        setKnifes(data.knifes)
      })
  }, [])

  return <>
    
    <header>
      <img src={Logo1024} alt="logo" id="main-logo"/>
      <img src={HeadTitle} alt="head title" id="head-title" />
      <a id="discover-button-link" href="#fabrication">
        <div id="discover-button">
          <p>Découvrir</p>
          <div id="discover-button_underline" />
        </div>
      </a>
    </header>

    <section id="fabrication" className="section-homepage">
      <img src={FabricationImage} alt="fabrication" className="section-homepage-image"/>
      <div className="section-homepage-content">
        <div className="section-title">
          <img src={EnclumeIcon} alt="enclume" />
          <div className="section-title-text-white">
            <h2>Fabrication</h2>
            <div className="section-title-divider" />
          </div>
        </div>
        <p>
          Dans mon atelier prend forme la magie de la création, au creux de mes mains l'acier, 
          le bois et le feu s'entremêlent pour donner vie à une création unique et 100% 
          artisanale. De la lame en passant par le manche, toutes mes créations sont uniques 
          et surtout artisanales, ce qui vous garantie de tenir entre vos mains une œuvre que 
          vous ne retrouverez nul part ailleurs.
        </p>
      </div>
    </section>

    <section id="derniers-couteaux">
      <h2>Mes derniers couteaux</h2>
      <div className="section-title-divider" />

      <div id="knife-list">
        {knifes.map((knife, i) => {
          return <KnifeBox key={i} data={knife}/>
        })}
      </div>

      <div id="knife-list-more">
        <a href="/couteaux">
          <p>Voir plus</p>
        </a>
      </div>
    </section>

    <StagesExplain />

    <Footer />

    <Navbar basket={props.basket} />
  </>
}
