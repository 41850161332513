import React from "react";

import "./style.css"

export default function MessageBox(props) {
  setTimeout(() => {
    let msgBox = document.querySelector(".message-box")
    if (msgBox) {
      msgBox.classList.remove("active")
    }

    setTimeout(() => {
      props.close()
    }, 300)
  }, 5000)

  setTimeout(() => {
    let msgBox = document.querySelector(".message-box")
    if (msgBox) {
      msgBox.classList.add("active")
    }
  }, 100)

  return <div className="message-box">
    {props.text}
  </div>
}