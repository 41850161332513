import React, { useEffect, useState } from "react"
import Footer from "../../components/footer"
import Navbar from "../../components/navbar"

import "./style.css"
import { Col, Form, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"


import { loadStripe } from "@stripe/stripe-js" 
import {Elements} from "@stripe/react-stripe-js"
import CheckoutForm from "../../components/checkoutForm"



const stripePromise = loadStripe("pk_live_51JZyXTHJDW1diCgl4YGt6jTmtgU2KKYrNFVqGUz4Tos8NmwO3ClqzvyqIgZicE2pG3V6wx4IwU2XdsF0S25jtSjk00TJl4Nua2")

export default function PaymentPage(props) {
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState({
    billing: {
      firstname: "",
      lastname: "",
      address: "",
      addressOptions: "",
      zipcode: "",
      city: "",
      country: "France",
      phone: "",
      mail: ""
    },
    shipping: {
      address: "",
      addressOptions: "",
      zipcode: "",
      city: "",
      country: "France",
    },
    shipOtherAddress: false
  })
  const [data, setData] = useState({
    total_price: 0,
    clientSecret: ""
  })
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    if (!props.basket || props.basket.length === 0) {
      navigate("/")
    } else {
      let fetchData = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "items": props.basket
        })
      }

      fetch("/api/payment/create-payment", fetchData)
        .then(res => {
          if (res.ok) {
            res.json()
              .then(data => setData(prev => prev.clientSecret ? prev : data))
          } else {
            navigate("/basket");
          }
        })
        
    }
  }, [props.basket, navigate])

  

  const appearance = {
    theme: 'stripe',
    variables: {
      colorBackground: '#F6E9D3',
      fontFamily: "GentiumBookBasic",
      colorText: "#1C1225",
      iconColor: "#1C1225"
    },
    rules: {
      ".Label": {
        color: "#F6E9D3"
      },
      ".Input:focus": {
        borderColor: "#EE7302",
        boxShadow: "0 0 0 0.25rem rgba(238, 115, 2, 0.3)"
      }
    }
  };
  const options = {
    clientSecret: data.clientSecret,
    appearance,
  };

  const handleSubmit = () => {
    const form = document.getElementById("form-bill");
    
    let res = true;

    if (form.checkValidity() === false) {      
      res = false;
    }

    setValidated(true);
  
    return res;
  }

  return <>
    <div id="payment-page">
      <h1>Paiement</h1>

      <div id="payment-container">
        <Form noValidate validated={validated} id="form-bill">
          <h2>Détails de facturation</h2>
          <Row className="mb-3">
            <Form.Group as={Col} md="6">
              <Form.Label htmlFor="form-billing-firstname">Prénom *</Form.Label>
              <Form.Control id="form-billing-firstname" type="text" placeholder="Prénom..." required
                value={formFields.billing.firstname}
                onChange={(e) => {setFormFields(prev => {return {
                  ...prev,
                  billing: {
                    ...prev.billing,
                    firstname: e.target.value.slice(0, 1).toUpperCase() + e.target.value.slice(1).toLowerCase()
                  }
                }})}}
              />
            </Form.Group>

            <Form.Group as={Col} md="6">
              <Form.Label htmlFor="form-billing-name">Nom *</Form.Label>
              <Form.Control id="form-billing-name" type="text" placeholder="Nom..." required
                value={formFields.billing.lastname}
                onChange={(e) => {setFormFields(prev => {return {
                  ...prev,
                  billing: {
                    ...prev.billing,
                    lastname: e.target.value.toUpperCase()
                  }
                }})}}
              />
            </Form.Group>
          </Row>

          <Form.Group as={Col} className="mb-3">
            <Form.Label htmlFor="form-billing-address">Numéro et nom de rue *</Form.Label>
            <Form.Control id="form-billing-address" type="text" placeholder="Numéro et nom de rue..." required 
              className="mb-1"
              value={formFields.billing.address}
              onChange={(e) => {setFormFields(prev => {return {
                ...prev,
                billing: {
                  ...prev.billing,
                  address: e.target.value
                }
              }})}}
            />
            <Form.Control id="form-billing-address-options" type="text" placeholder="Bâtiment, appartement, lot, etc. (facultatif)" 
              value={formFields.billing.addressOptions}
              onChange={(e) => {setFormFields(prev => {return {
                ...prev,
                billing: {
                  ...prev.billing,
                  addressOptions: e.target.value
                }
              }})}}
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-3">
            <Form.Label htmlFor="form-billing-zipcode">Code postal *</Form.Label>
            <Form.Control id="form-billing-zipcode" type="number" placeholder="Code postal..." required 
              value={formFields.billing.zipcode}
              onChange={(e) => {setFormFields(prev => {return {
                ...prev,
                billing: {
                  ...prev.billing,
                  zipcode: e.target.value
                }
              }})}}
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-3">
            <Form.Label htmlFor="form-billing-city">Ville *</Form.Label>
            <Form.Control id="form-billing-city" type="text" placeholder="Ville..." required 
              value={formFields.billing.city}
              onChange={(e) => {setFormFields(prev => {return {
                ...prev,
                billing: {
                  ...prev.billing,
                  city: e.target.value.toUpperCase()
                }
              }})}}
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-3">
            <Form.Label htmlFor="form-billing-country">Pays *</Form.Label>
            <Form.Select id="form-billing-country"
              value={formFields.billing.country}
              onChange={(e) => {
                setFormFields(prev => {
                  return {
                    ...prev,
                    billing: {
                      ...prev.billing,
                      country: e.target.value
                    }
                  }
                })
              }}
            >
              <option value="France">France</option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} className="mb-3">
            <Form.Label htmlFor="form-billing-phone">Téléphone *</Form.Label>
            <Form.Control id="form-billing-phone" type="phone" placeholder="Téléphone..." required 
              value={formFields.billing.phone}
              onChange={(e) => {setFormFields(prev => {return {
                ...prev,
                billing: {
                  ...prev.billing,
                  phone: e.target.value
                }
              }})}}
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-3">
            <Form.Label htmlFor="form-billing-mail">Adresse mail *</Form.Label>
            <Form.Control id="form-billing-mail" type="email" placeholder="Adresse mail..." required 
              value={formFields.billing.mail}
              onChange={(e) => {setFormFields(prev => {return {
                ...prev,
                billing: {
                  ...prev.billing,
                  mail: e.target.value
                }
              }})}}
            />
          </Form.Group>

          <div id="row-different-address">
            <Form.Label htmlFor="form-billing-other-address">Expédier à une adresse différente ?</Form.Label>
            <Form.Check id="form-billing-other-address" value={formFields.shipOtherAddress}
              onChange={() => setFormFields(prev => {
                return {...prev, shipOtherAddress: !prev.shipOtherAddress}
              })}
            />
          </div>

          {formFields.shipOtherAddress &&
            <>
              <Row className="mb-3">
                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="form-shipping-firstname">Prénom *</Form.Label>
                  <Form.Control id="form-shipping-firstname" type="text" placeholder="Prénom..." required
                    value={formFields.shipping.firstname}
                    onChange={(e) => {setFormFields(prev => {return {
                      ...prev,
                      shipping: {
                        ...prev.shipping,
                        firstname: e.target.value.slice(0, 1).toUpperCase() + e.target.value.slice(1).toLowerCase()
                      }
                    }})}}
                  />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="form-shipping-name">Nom *</Form.Label>
                  <Form.Control id="form-shipping-name" type="text" placeholder="Nom..." required
                    value={formFields.shipping.lastname}
                    onChange={(e) => {setFormFields(prev => {return {
                      ...prev,
                      shipping: {
                        ...prev.shipping,
                        lastname: e.target.value.toUpperCase()
                      }
                    }})}}
                  />
                </Form.Group>
              </Row>

              <Form.Group as={Col} className="mb-3">
                <Form.Label htmlFor="form-shipping-address">Numéro et nom de rue *</Form.Label>
                <Form.Control id="form-shipping-address" type="text" placeholder="Numéro et nom de rue..." required 
                  className="mb-1"
                  value={formFields.shipping.address}
                    onChange={(e) => {setFormFields(prev => {return {
                      ...prev,
                      shipping: {
                        ...prev.shipping,
                        address: e.target.value
                      }
                    }})}}
                />
                <Form.Control id="form-shipping-address-options" type="text" placeholder="Bâtiment, appartement, lot, etc. (facultatif)" 
                  value={formFields.shipping.addressOptions}
                  onChange={(e) => {setFormFields(prev => {return {
                    ...prev,
                    shipping: {
                      ...prev.shipping,
                      addressOptions: e.target.value
                    }
                  }})}}
                />
              </Form.Group>

              <Form.Group as={Col} className="mb-3">
                <Form.Label htmlFor="form-shipping-zipcode">Code postal *</Form.Label>
                <Form.Control id="form-shipping-zipcode" type="number" placeholder="Code postal..." required 
                  value={formFields.shipping.zipcode}
                  onChange={(e) => {setFormFields(prev => {return {
                    ...prev,
                    shipping: {
                      ...prev.shipping,
                      zipcode: e.target.value
                    }
                  }})}}
                />
              </Form.Group>

              <Form.Group as={Col} className="mb-3">
                <Form.Label htmlFor="form-shipping-city">Ville *</Form.Label>
                <Form.Control id="form-shipping-city" type="text" placeholder="Ville..." required 
                  value={formFields.shipping.city}
                  onChange={(e) => {setFormFields(prev => {return {
                    ...prev,
                    shipping: {
                      ...prev.shipping,
                      city: e.target.value.toUpperCase()
                    }
                  }})}}
                />
              </Form.Group>

              <Form.Group as={Col} className="mb-3">
                <Form.Label htmlFor="form-shipping-country">Pays *</Form.Label>
                <Form.Select id="form-shipping-country"
                  value={formFields.shipping.country}
                  onChange={(e) => {
                    setFormFields(prev => {
                      return {
                        ...prev,
                        shipping: {
                          ...prev.shipping,
                          country: e.target.value
                        }
                      }
                    })
                  }}                
                >
                  <option value="France">France</option>
                </Form.Select>
              </Form.Group>
            </>
          }
        </Form>

        <div id="payment-summary">
          <h3>Votre panier</h3>
          <table>
            <thead>
              <tr>
                <th className="product-name">Produit</th>
                <th className="product-quantity">Qté</th>
                <th className="product-total">Prix</th>
              </tr>
            </thead>
            <tbody>
              {props.basket && props.basket.map((item, index) => {
                return <tr className="payment-summary-item" key={index}>
                  <td className="product-name">{item.name}</td>
                  <td className="product-quantity">{item.quantity ? item.quantity : 1}</td>
                  <td className="product-total">{item.price.toFixed(2)}€</td>
                </tr>
              })}
            </tbody>
            <tfoot>
              <tr className="basket-total">
                <td>Total</td>
                <td></td>
                <td>{data.total_price.toFixed(2)}€</td>
              </tr>
            </tfoot>
          </table>
          {data.clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm 
                totalPrice={data.total_price} 
                basket={props.basket} 
                clientSecret={data.clientSecret}
                checkBillingInfos={handleSubmit}
                user={formFields}
                setBasket={props.setBasket}
              />
            </Elements>
          )}
        </div>
      </div>
    </div>

    <Footer />

    <Navbar basket={props.basket} />
  </>
}