import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar";
import { useNavigate } from "react-router-dom"

import "./style.css"

import { Button, Col, Form } from "react-bootstrap";

export default function AdminLogin(props) {
  const [loginAttempt, setLoginAttempt] = useState(0);
  const [formInput, setFormInput] = useState({
    username: "",
    password: ""
  });
  const [validate, setValidate] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    fetch("/api/admin/is-admin")
      .then(res => {
        if (res.ok) {
          navigate("/admin/dashboard");
        }
      })
  }, [navigate])

  const handleSumbit = e => {
    if (loginAttempt >= 5) {
      return;
    }

    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      fetch("/api/admin/login", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(formInput)
      })
        .then(res => {
          if (res.ok) {
            navigate("/admin/dashboard")
          } else {
            setLoginAttempt(prev => prev + 1);
            alert("Identifiants invalides.")
          }
        })
    }

    setValidate(true);
  }

  return <>
    <div id="admin-login-page">
      <Form noValidate validated={validate} onSubmit={handleSumbit}>
        <Form.Group as={Col} className="mb-3">
          <Form.Label mb={3}>Nom d'utilisateur</Form.Label>
          <Form.Control type="text" required placeholder="Nom d'utilisateur..."
            value={formInput.username} onChange={e => setFormInput(prev => {
              return {...prev, username: e.target.value}
            })}
          />        
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control type="password" required placeholder="Mot de passe..."
            value={formInput.password} onChange={e => setFormInput(prev => {
              return {...prev, password: e.target.value}
            })}/>
        </Form.Group>

        <Button type="submit">
          Se connecter
        </Button>
      </Form>
    </div>
  
    <Navbar basket={props.basket} />
  </>
}