import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";

import { Carousel, Form } from "react-bootstrap";

import "./style.css"
import Footer from "../../components/footer";
import KnifeBox from "../../components/knifeBox";
import { useNavigate, useParams } from "react-router-dom";
import MessageBox from "../../components/messageBox";

export default function KnifeDetailsPage(props) {
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const [inBasket, setInBasket] = useState(false);
  const [msgBoxOpen, setMsgBoxOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const params = useParams();

  useEffect(() => {
    fetch("/api/knifes/get_infos?id=" +  params.knifeId)
      .then(res => {
        if (res.ok) {
          res.json()
            .then(data => {
              setData(data);
            })
        } else {
          navigate("/couteaux")
        }
      })

  }, [params, navigate])

  useEffect(() => {
    if (data) {
      for (let item of props.basket) {
        if (item.id === data.id && item.type === "knife") {
          setInBasket(true)
        } 
      }
    }
  }, [data, props.basket])

  const addToBasket = () => {
    props.setBasket(prev => {
      return [...prev, {
        "id": data.id,
        "name": data.name,
        "price": data.price,
        "type": "knife",
        "image": data.images[0],
        "quantity": quantity
      }]
    })

    setInBasket(true)
    setMsgBoxOpen(true);
  }

  if (data) {
    return <>
      <div id="knife-details-page">
        <div id="knife-details-pages-main-infos">
          <div id="knife-details-images">
            <Carousel>
              {data.images.map((img, key) => <Carousel.Item key={key}>
               <img src={`/api/knifes/img?id=${data.id}&fn=${img}`} alt="preview" />
              </Carousel.Item>)}
            </Carousel>
          </div>

          <div id="knife-details-content">
            <h1>{data.name}<br/>{data.price.toFixed(2)}€</h1>
            <div id="knife-details-content-description" dangerouslySetInnerHTML={{ __html: data.description}}></div>

            <div id="knife-details-articles-quantity">
              {data.available > 1 &&
              <>
                <h3>Quantité :</h3>
                <Form.Select value={quantity} onChange={e => setQuantity(e.target.value)}>
                  {Array.from(Array(data.available).keys()).map(item => {
                    return <option value={item + 1} key={item}>{item + 1}</option>
                  })}
                </Form.Select>
              </>
              }
            </div>
    
            {data.available > 0 &&
            <>
              {inBasket ?
                <>
                  <div id="knife-details-add-to-cart" style={{cursor: "default"}}>
                    <p>Dans votre panier !</p>
                  </div>
                </>
                :
                <>
                  <div id="knife-details-add-to-cart" onClick={addToBasket}>
                    <p>Ajouter au panier</p>
                  </div>
                </>
              }
              
              
            
            </>
            }
            {data.available === 0 &&
              <div id="knife-details-out-of-stock">
                <p>Rupture de stock !</p>
              </div>
            }
            

            
          </div>
        </div>

        <div id="knife-details-page-more-knifes">
          <h2>Découvrez d’autres produits</h2>
          <div id="knife-details-page-more-knifes-list">
            {data.random_knifes.map(knife => {
              return <KnifeBox data={knife} key={knife.id}/>
            })}
          </div>
        </div>
      </div>
      
      <Footer />

      {msgBoxOpen && <MessageBox text={`"${data.name}" a été ajouté à votre panier`} close={() => setMsgBoxOpen(false)}/>}

      <Navbar basket={props.basket} />
    </>
  } else {
    return <>
      <Navbar basket={props.basket} />
    </>
  }

}
