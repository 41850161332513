import React, { useEffect, useState } from "react";

import "./style.css"
import Navbar from "../../../components/navbar";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";


export default function AdminListItems (props) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [data, setData] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    fetch(`/api/admin/${params.type}/list`)
      .then(res => {
        if (res.ok) {
          setIsAdmin(true);
          res.json()  
            .then(data => setData(data));
        } else {
          navigate("/admin")
        }
      })
  }, [params.type, navigate])

  const searchFunction = (e) => {
    if (String(e.name).includes(searchBar))
      return e;
  }


  if (isAdmin) {
    return <>
      <div id="admin-list-page">
        <h1>Liste des {params.type === "knifes" ? "couteaux" : "stages"}</h1>

        <Form.Control type="text" placeholder="Rechercher"
         value={searchBar} onChange={e => setSearchBar(e.target.value)}/>

        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nom</th>
              <th>Prix</th>
              <th>Disponible</th>
            </tr>
          </thead>

          <tbody>
            {data.filter(searchFunction).map(knife => <tr key={knife.id} onClick={() => navigate(`/admin/edit/${params.type.slice(0, -1)}/${knife.id}`)}>
              <td>{knife.id}</td>
              <td>{knife.name}</td>
              <td>{knife.price.toFixed(2)}€</td>
              <td>{knife.available ? "DISPO" : "VENDU"}</td>
            </tr>)}
          </tbody>
        </table>
      </div>
    
      <Navbar basket={props.basket} />
    </>
  } else {
    return <>
    
    </>
  }

}
