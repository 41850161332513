import React from "react";

import  "./style.css"


export default function StageBox(props) {



  return <div className="stage-box">
    <div className="stage-box-image">
      <img src={`/api/stages/img?id=${props.data.id}&fn=${props.data.image}`} alt="stage preview" />
    </div>

    <div className="stage-box-infos">
      <h3 className="stage-box-title">{props.data.name}</h3>
      <h4 >{props.data.price.toFixed(2)}€ - {props.data.date}</h4>
      <div className="stage-box-description" dangerouslySetInnerHTML={{ __html: props.data.description}} />


      <div className="stage-box-price"></div>
      <div className="stage-book-button-container">
        <div className="stage-book-button">
          {props.data.available && !props.data.inBasket && 
            <div className="stage-book-button-text" onClick={() => props.addFunction()}>
              Réserver
            </div>
          }
          {props.data.inBasket && props.data.available && 
            <div className="stage-book-button-text" style={{cursor: "default"}}>
              Dans votre panier
            </div>
          }
          {!props.data.available &&
            <div className="stage-book-button-text" style={{cursor: "default"}}>
              COMPLET
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}